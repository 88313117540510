import { convertTime, createURL, getWeekDate, removeDash } from "../utils";
import { getStatus, formatDate, capitalizeFirst } from "./utils";
import { Tooltip, TooltipContent } from "./Tooltip";
import { Menu } from "@mantine/core";
import "./styles.css";
import { BiTrash } from "react-icons/bi";
import { createNotification } from "../Notifications";

const StatusIndicator = ({ status }) => {
  return (
    <div className="status" style={{ backgroundColor: `${status.color}` }}>
      {status.icon}
      <div className="noun">{status.noun}</div>
    </div>
  );
};

const Appointment = ({ room, handleDelete }) => {
  const {
    appDate,
    appTime,
    patientFirst,
    patientLast,
    patientStatus,
    clinicianLast,
    roomName,
    meetingId,
  } = room;
  let { camera, mic, connectivity, participation } = patientStatus;
  const base_url = "http://rsbcihi.afyachat.net";
  const status = getStatus(camera, mic, connectivity, participation);
  const url = createURL(base_url, meetingId);
  const rawId = removeDash(meetingId);
  const content = <TooltipContent status={patientStatus} />;
  return (
    <div className="card" style={{ borderLeft: `6px solid ${status.color}` }}>
      <div>
        <div className="cardRow">
          <Menu>
            <Menu.Item
              icon={<BiTrash />}
              onClick={() => {
                handleDelete(meetingId);
              }}
            >
              Delete Appointment
            </Menu.Item>
          </Menu>
        </div>
        <div className="cardBody">
          <div className="timeSection">
            <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
              {convertTime(appTime)}
            </div>
            <div style={{ color: "#5B5B5B" }}>
              {getWeekDate(appDate, appTime)}
            </div>
          </div>
          <div className="line"></div>
          <div className="names">
            <div style={{ marginRight: "5px" }}>
              {capitalizeFirst(patientFirst)}
            </div>
            {capitalizeFirst(patientLast)}
          </div>
          <div className="statusSection">
            <Tooltip content={content}>
              <StatusIndicator status={status} />
            </Tooltip>
          </div>
          <div className="dateSection">{formatDate(appDate)}</div>
          <div className="actionSection">
            <button
              className="joinBtn"
              onClick={() => {
                window.open(`${base_url}/r/${rawId}`, "_blank");
              }}
            >
              Join
            </button>
            <button
              onClick={() => {
                navigator.clipboard.writeText(`${base_url}/r/${rawId}`);
                createNotification("Copied to Clipboard!", false);
              }}
              className="copyBtn"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
