import rsbcihiLogo from "../assets/rsbcihi-logo-blue.png";
import { Link } from "react-router-dom";
import "./styles.css";

const Logo = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "80%",
        textDecoration: "none",
      }}
    >
      <img src={rsbcihiLogo} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Link
          to="./"
          style={{
            color: "#4985A4",
            fontSize: "25px",
            marginBottom: "-3px",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          RSBCIHI
        </Link>
        <Link
          to="./"
          style={{
            color: "#638A2F",
            fontSize: "25px",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          Telehealth
        </Link>
      </div>
    </div>
  );
};

export default Logo;
